<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <HeaderPanel
        title="Mission"
        :filter="filter"
        placeholder="Search Name"
        :hasDropdown="false"
        @search="handleSearch"
        @sidebar="sidebarFilter"
        routerPath="/mission/details/0"
      />

      <div>
        <SideBarFilter
          ref="sidebarFilter"
          :filter="filter"
          placeholder="Search Name"
          @searchAll="handleSearch"
          @clearFilter="clearFilter"
        >
          <template v-slot:filter-option>
            <div class="mt-3">
              <div>
                <div class="font-weight-bold mb-2">Valid From</div>
                <div :class="['input-container']">
                  <datetime
                    type="datetime"
                    :input-style="styleDatetime"
                    format="dd/MM/yyyy HH:mm:ss"
                    value-zone="local"
                    v-model="filter.valid_from"
                    ref="startDate"
                    placeholder="Please Select Date"
                  >
                  </datetime>
                  <div
                    class="icon-primary text-right"
                    @click="$refs.startDate.isOpen = true"
                  >
                    <font-awesome-icon
                      icon="calendar-alt"
                      class="pointer color-primary"
                      color="#B41BB4"
                    />
                  </div>
                </div>
              </div>
              <div class="mt-3 mb-3">
                <div class="font-weight-bold mb-2">Valid To</div>
                <div :class="['input-container']">
                  <datetime
                    type="datetime"
                    :input-style="styleDatetime"
                    format="dd/MM/yyyy HH:mm:ss"
                    value-zone="local"
                    v-model="filter.valid_to"
                    :min-datetime="filter.valid_from"
                    placeholder="Please Select Date"
                    ref="endDate"
                  >
                  </datetime>
                  <div
                    class="icon-primary text-right"
                    @click="$refs.endDate.isOpen = true"
                  >
                    <font-awesome-icon
                      icon="calendar-alt"
                      class="pointer color-primary"
                      color="#B41BB4"
                    />
                  </div>
                </div>
              </div>
            </div>
          </template>
        </SideBarFilter>
      </div>
      <div class="bg-white border-red p-2 p-lg-3 mt-3">
        <b-row>
          <b-col>
            <b-table
              responsive
              striped
              hover
              :fields="fields"
              :items="items"
              :busy="isBusy"
              show-empty
              empty-text="No matching records found"
            >
              <template v-slot:cell(name)="data">
                <div class="justify-content-center">
                  <router-link :to="'/mission/details/' + data.item.id">
                    <u> {{ data.item.name }}</u>
                  </router-link>
                </div>
              </template>
              <template v-slot:cell(new_friend_count)="data">
                <span>
                  {{ data.item.new_friend_count | numeral("0,0") }}
                </span>
              </template>
              <template v-slot:cell(valid_from)="data">
                <template v-if="data.item.valid_type == 1">
                  <div>-</div>
                </template>
                <template v-else>
                  <div>{{ data.item.valid_from | moment($formatDateNew) }}</div>
                  <div class="time-color">
                    {{ data.item.valid_from | moment("HH:mm:ss") }}
                  </div>
                </template>
              </template>
              <template v-slot:cell(valid_to)="data">
                <template v-if="data.item.valid_type == 1">
                  <div>-</div>
                </template>
                <template v-else>
                  <div>{{ data.item.valid_to | moment($formatDateNew) }}</div>
                  <div class="time-color">
                    {{ data.item.valid_to | moment("HH:mm:ss") }}
                  </div>
                </template>
              </template>
              <template v-slot:cell(status)="data">
                <div
                  :class="[
                    data.item.status == 1 ? 'text-success' : 'text-error',
                  ]"
                >
                  {{ $helper.status(data.item.status) }}
                </div>
              </template>
              <template v-slot:cell(action)="data">
                <div class="d-flex justify-content-center">
                  <router-link :to="'/mission/details/' + data.item.id">
                    <b-button variant="link" class="text-warning px-1 py-0">
                      <font-awesome-icon icon="pencil-alt" title="Edit" />
                    </b-button>
                  </router-link>
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <Pagination
          @handleChangeTake="handleChangeTake"
          :pageOptions="pageOptions"
          :filter="filter"
          :rows="rows"
          @pagination="pagination"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SideBarFilter from "@/components/SideBarFilter";
import OtherLoading from "@/components/loading/OtherLoading";
import Pagination from "@/components/Pagination";
export default {
  components: {
    OtherLoading,
    SideBarFilter,
    Pagination,
  },

  data() {
    return {
      fields: [
        {
          key: "name",
          label: "Name",
          class: "text-center",
          thStyle: { width: "35%" },
        },
        {
          key: "number_of_branchs",
          label: "Number Of Branches",
        },
        {
          key: "valid_from",
          label: "Valid From",
        },
        {
          key: "valid_to",
          label: "Valid To",
        },
        {
          key: "status",
          label: "Status",
        },
      ],
      items: [],
      isBusy: false,
      isLoadingData: false,
      checkAll: false,
      selectAllCb: false,
      rows: 0,
      showing: 1,
      showingTo: 10,
      checkboxActive: false,
      checkboxInactive: false,
      filter: {
        search: "",
        page: 1,
        take: 10,
      },
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      selected: 0,
      isLoading: true,
      styleDatetime: {
        width: "100%",
        border: "none",
      },
    };
  },
  created: async function () {
    await this.getList();

    this.isLoading = false;
  },
  methods: {
    getList: async function () {
      this.isBusy = true;

      var { data } = await this.axios.post("/mission/list", this.filter);
      this.isLoading = false;

      if (data.result == 1) {
        this.items = data.detail.data;
        this.rows = data.detail.count;
        this.isLoadingData = false;
      }
      this.isBusy = false;
    },
    handleSearch(value) {
      this.filter = value;
      this.getList();
    },
    pagination(page) {
      this.filter.page = page;
      this.$emit("filterPage", this.filter);
      this.getList();
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.$emit("filterPage", this.filter);
      this.getList();
    },
    clearFilter() {
      this.filter = {
        search: "",
        page: 1,
        take: 10,
        status: [],
        valid_from: "",
        valid_to: "",
      };
    },
    sidebarFilter() {
      this.$refs.sidebarFilter.show(true);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep thead {
  background-color: var(--theme-secondary-color);
  color: var(--font-color);
}
.time {
  color: #afafaf;
}
</style>
